/**
 *
 * List of operators used in the Imagine JS SDK
 *
 * See https://github.platforms.engineering/pages/location-360/imagine-javascript-sdk/globals.html#itemsearchcomparison
 * for list of current operators
 */

import {SearchPropertyComparisonOperator} from '../context/search/SearchParameters'

const operators = [
  {
    value: SearchPropertyComparisonOperator.GEQ,
    usedWith: ['number'],
    description: 'Greater or equal to',
  },
  {
    value: SearchPropertyComparisonOperator.LEQ,
    usedWith: ['number'],
    description: 'Less or equal to',
  },
  {
    value: SearchPropertyComparisonOperator.GT,
    usedWith: ['number'],
    description: 'Greater than',
  },
  {
    value: SearchPropertyComparisonOperator.LT,
    usedWith: ['number'],
    description: 'Less than',
  },
  {
    value: SearchPropertyComparisonOperator.EQ,
    usedWith: ['number', 'text', 'boolean'],
    description: 'Equal to',
  },
  {
    value: SearchPropertyComparisonOperator.NEQ,
    usedWith: ['number', 'text'],
    description: 'Not equal to',
  },
  {
    value: SearchPropertyComparisonOperator.NULL,
    usedWith: ['number', 'text', 'boolean'],
    description: 'Is null',
  },
]

export default operators
