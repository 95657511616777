import React, {useContext} from 'react'
import styled from 'styled-components'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Link} from 'react-router-dom'

import {Table, TableBody, TableCell, TableContainer, TableRow} from '@mui/material'

import {itemContext} from '../context/itemContext'
import useToastNotification from '../hooks/useToastNotification'

import ItemMetadataHeaderAccordion from './ItemMetadataHeaderAccordion'
import {useSettings} from '../context/settingsContext'

const cellStyle = {paddingLeft: 8, paddingRight: 8}

const ThumbnailContainer = styled.div`
  min-width: 130px;
  margin: auto 5px auto 8px;

  img {
    max-width: 128px;
  }
`

/**
 * Table that lists id, collection and project.
 */
function ItemMetadataSummaryInfo() {
  const {settings} = useSettings()

  const context = useContext(itemContext)

  const {
    itemMetadata: {id, collection, project, thumbnail, assetsWithThumbnail, videoUrl, animatedUrl},
  } = context

  const {infoNotification} = useToastNotification()
  const hasAnimatedPreview = videoUrl || animatedUrl

  return (
    <>
      <ItemMetadataHeaderAccordion header="Summary">
        {hasAnimatedPreview && (
          <div style={{display: 'flex', maxHeight: '450px', marginTop: '10px'}}>
            {videoUrl ? (
              <video
                style={{width: '100%', height: 'auto', objectFit: 'contain'}}
                src={videoUrl}
                controls
                autoPlay={settings.itemDetails.autoPlayVideo}
                muted
              />
            ) : (
              animatedUrl && (
                <img
                  style={{width: '100%', height: 'auto', objectFit: 'contain'}}
                  src={animatedUrl}
                />
              )
            )}
          </div>
        )}
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <>
            {assetsWithThumbnail?.length === 1 && (
              <ThumbnailContainer>
                <img src={thumbnail} alt={`thumbnail for ${project} project`} />
              </ThumbnailContainer>
            )}
            <TableContainer>
              <Table sx={{minWidth: '450px'}} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell sx={{cellStyle}} component="th" scope="row">
                      Project
                    </TableCell>
                    <TableCell sx={{cellStyle}} align="left">
                      {project ? (
                        <Link to={`/project/${project}`}>{project}</Link>
                      ) : (
                        <span className="subdue">(Error: No Project)</span>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{cellStyle}} component="th" scope="row">
                      Collection
                    </TableCell>
                    <TableCell sx={{cellStyle}} align="left">
                      {collection ? (
                        <Link to={`/collection/${collection}`}>{collection}</Link>
                      ) : (
                        <span className="subdue">(Error: No Collection)</span>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{cellStyle}} component="th" scope="row">
                      ID
                    </TableCell>
                    <TableCell sx={{cellStyle}} align="left">
                      <CopyToClipboard
                        text={id}
                        onCopy={() => infoNotification('Item ID copied to clipboard')}
                      >
                        <a href="#">{id}</a>
                      </CopyToClipboard>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </div>
      </ItemMetadataHeaderAccordion>
    </>
  )
}

export default ItemMetadataSummaryInfo
