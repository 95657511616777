import type {Item} from '@bayer-int/imagine-sdk-browser'

// We don't know what codec is used but assume it is supported if the media type is here
const SUPPORTED_VIDEO_MEDIA_TYPE = [
  'video/3gpp',
  'video/3gp2',
  'video/3gpp2',
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/quicktime',
  'video/webm',
  'video/x-matroska',
  'video/x-ms-asf',
]

export function isSupportedVideoMediaType(mediaType: string) {
  return SUPPORTED_VIDEO_MEDIA_TYPE.some(t => mediaType.includes(t))
}

export async function findVideoAssetUrl(item: Item): Promise<string> {
  let videoUrl = ''
  try {
    const assets = await item.fetchAssets()
    const videoAssets = assets.filter(a => isSupportedVideoMediaType(a.mediaType))
    const video = videoAssets?.[0]
    videoUrl = await video?.fetchDownloadUrl()
  } catch (err) {}
  return videoUrl
}
