import React, {createContext, useContext, useState} from 'react'
import {telemetry} from '../utilities/telemetry'

const IMAGINE_USER_SETTINGS = 'IMAGINE_USER_SETTINGS '

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>
}

function deepMerge<T>(target: T, source: RecursivePartial<T>): T {
  const result = {...target} as T
  for (const key in source) {
    if (target.hasOwnProperty(key) && source.hasOwnProperty(key)) {
      const targetValue = target[key]
      const sourceValue = source[key] as T[Extract<keyof T, string>] // restate type for TS compiler; validated by conditional above

      if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
        result[key] = sourceValue
      } else if (isObject(targetValue) && isObject(sourceValue)) {
        result[key] = deepMerge(targetValue, sourceValue)
      } else {
        result[key] = sourceValue
      }
    }
  }
  return result
}

function isObject(item: any): item is Record<string, any> {
  return item !== null && typeof item === 'object' && !Array.isArray(item)
}

export enum SearchType {
  KEYWORD = 'keyword',
  CQL = 'cql',
}

export enum SearchDisplayWrap {
  WRAP = 'wrap',
  NO_WRAP = 'no-wrap',
}

const initialState: Settings = {
  search: {
    searchType: SearchType.KEYWORD,
    display: {wrap: SearchDisplayWrap.NO_WRAP},
  },
  dialog: {
    open: false,
  },
  itemDetails: {
    autoPlayVideo: true,
  },
}

export type SearchConfig = {
  searchType: SearchType
  display: {wrap: SearchDisplayWrap}
}

type DialogConfig = {
  open: boolean
}

type ItemDetailsConfig = {
  autoPlayVideo: boolean
}

export type Settings = {
  search: SearchConfig
  dialog: DialogConfig
  itemDetails: ItemDetailsConfig
}

const settingsContext = createContext({
  settings: initialState,
  setSettings: (() => {}) as React.Dispatch<React.SetStateAction<Settings>>,
})
const {Provider} = settingsContext

function getInitialState() {
  const settings = localStorage.getItem(IMAGINE_USER_SETTINGS)
  if (settings) {
    try {
      const storedSettings = JSON.parse(settings)
      return deepMerge(initialState, storedSettings) // we merge with initialState to protect against settings changes
    } catch (err) {
      telemetry.error(
        err,
        `Error parsing Imagine user settings in local storage for value '${settings}'.`
      )
    }
  }
  return initialState
}

function SettingsProvider({children}) {
  const [settings, setSettings] = useState<Settings>(getInitialState())

  return <Provider value={{settings, setSettings}}>{children}</Provider>
}

function useSettings() {
  const {settings, setSettings} = useContext(settingsContext)

  function updateSettings(s: RecursivePartial<Settings>) {
    const merged = deepMerge(settings, s)
    localStorage.setItem(IMAGINE_USER_SETTINGS, JSON.stringify(merged))
    setSettings(merged)
  }

  function setSearchDisplayWrap(wrap: SearchDisplayWrap) {
    updateSettings({
      search: {
        display: {
          wrap,
        },
      },
    })
  }

  function setSearchType(searchType: SearchType) {
    updateSettings({
      search: {
        searchType,
      },
    })
  }

  function openSettings() {
    updateSettings({
      dialog: {
        open: true,
      },
    })
  }

  function closeSettings() {
    updateSettings({
      dialog: {
        open: false,
      },
    })
  }

  function setItemDetailsAutoplay(autoplay: boolean) {
    updateSettings({
      itemDetails: {
        autoPlayVideo: autoplay,
      },
    })
  }

  return {
    settings,
    setSearchType,
    openSettings,
    closeSettings,
    setSearchDisplayWrap,
    setItemDetailsAutoplay,
  }
}

export {useSettings, SettingsProvider}
