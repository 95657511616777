import * as Sentry from '@sentry/react'
import * as SentryBrowser from '@sentry/browser'
import {ExtraErrorData} from '@sentry/integrations'
import {BrowserTracing} from '@sentry/tracing'
import {getEnvironment, isLocal} from './serviceBindings'
import {datadogRum} from '@datadog/browser-rum'
import {datadogLogs} from '@datadog/browser-logs'

export function setupTelemetry() {
  try {
    const env = getEnvironment()
    SentryBrowser.init({
      dsn: 'https://5cada471664441a78c3a1ef406471dcb@o1128229.ingest.sentry.io/4503976835612672',
      integrations: [new BrowserTracing(), new ExtraErrorData()],
      environment: env,
      tracesSampleRate: 1.0,
    })
    datadogRum.init({
      applicationId: '2dd3cdbc-c74d-43a7-86c6-800eb8d8e453',
      clientToken: 'pub7a4037ff6cbd1db36c00043ce5e94e89',
      site: 'datadoghq.com',
      service: 'imagine-ui',
      env,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        /https:\/\/.*\.bayer\.com/,
        /https:\/\/.*\.phoenix-tools(-np)?\.io/,
        /https:\/\/.*\.agro\.services/,
        /https:\/\/.*\.velocity(-np)?\.ag/,
      ],
    })
  } catch (err) {
    console.error(err)
  }
}

export type TelemetryOptions = {
  telemetry?: ITelemetry
  level?: 'error' | 'warning' | 'info' | 'debug'
}

class SentryTelemetry {
  debug(msg: string) {
    Sentry.captureMessage(msg, {
      level: 'debug',
    })
  }

  info(msg: string) {
    Sentry.captureMessage(msg, {level: 'info'})
  }

  warn(msg: string) {
    Sentry.captureMessage(msg, {level: 'warning'})
  }

  error(err: Error) {
    Sentry.captureException(err)
  }
}

class DatadogTelemetry {
  debug(msg: string) {
    datadogLogs.logger.debug(msg)
  }

  info(msg: string) {
    datadogLogs.logger.info(msg)
  }

  warn(msg: string) {
    datadogLogs.logger.warn(msg)
  }

  error(err: Error) {
    datadogLogs.logger.error(err.message, {}, err)
  }
}

type ITelemetry = {
  debug(msg: string): void
  info(msg: string): void
  warn(msg: string): void
  error(err: Error): void
}

class Telemetry {
  telemetry: ITelemetry
  options: TelemetryOptions

  constructor(options?: TelemetryOptions) {
    this.options = {level: 'info', ...(options ?? {})}
  }

  configure(options: Partial<TelemetryOptions>) {
    this.options = {...this.options, ...options}
  }

  debug(msg: any) {
    console.debug(msg)
    if (isLocal) {
      return
    }
    try {
      const text = typeof msg === 'string' ? msg : JSON.stringify(msg, null, 2)
      this.telemetry.debug(text)
    } catch (err) {
      console.error(err)
    }
  }

  info(msg: string) {
    console.info(msg)
    if (isLocal) {
      return
    }
    try {
      this.telemetry.info(msg)
    } catch (err) {
      console.error(err)
    }
  }

  warn(msg: string) {
    console.warn(msg)
    if (isLocal) {
      return
    }
    try {
      this.telemetry.warn(msg)
    } catch (err) {
      console.error(err)
    }
  }

  error(err: Error, details?: string) {
    const combinedError = details ? new Error(details, {cause: err}) : err
    console.error(combinedError)
    if (isLocal) {
      return
    }
    try {
      this.telemetry.error(combinedError)
    } catch (err) {
      console.error(err)
    }
  }
}

class MockTelemetry {
  debug(msg: string): void {}
  info(msg: string): void {}
  warn(msg: string): void {}
  error(err: Error): void {}
}

export const telemetry = new Telemetry({
  telemetry: new DatadogTelemetry(),
})
