import React from 'react'
import PropTypes from 'prop-types'
import {IconButton, Typography} from '@mui/material'
import {Delete as DeleteIcon} from '@mui/icons-material'
import styled, {css} from 'styled-components'

const SelectedAttributeElement = styled.div`
  display: grid;
  grid-template-columns: 300px 40px 1fr 30px;
  grid-column-gap: 10px;

  padding-left: 15px;
  padding-right: 5px;
  margin-bottom: 4px;

  color: #767676;
  border: 1px solid #767676;
  background-color: #fff;
  border-radius: 3px;
  transition: all 0.35s ease-in-out;

  &:hover {
    background-color: #efefee;
    border: 1px solid var(--mdc-theme-primary);
    color: #333;
  }

  .property,
  .value,
  .operator {
    align-self: center;
    display: flex;
  }

  ${props =>
    props.keyword &&
    css`
      .value {
        justify-self: end;
        font-style: oblique;
        padding-right: 5px;
      }
    `}

  .property,
  .operator,
  .value {
    margin-right: 10px;
  }

  ${props =>
    props.useOperator &&
    css`
      .property p {
        font-weight: 500;
      }
    `}

  .value {
    grid-column: 3 / 4;
  }

  .delete-icon {
    grid-column: 4 / 5;
  }
`

/**
 *
 * Full width pill element. Displays search criteria for projects, collections, collection attributes, or keywords
 *
 *
 */
function AdvancedSearchSelectedAttribute(props) {
  const {property, operator, value, onDelete, keyword, title} = props

  let displayValue = value

  // Searches for and cuts off the \" on the start and the end of the term
  const literalQuotesRe = /^\"(.+)\"$/
  if (literalQuotesRe?.test(displayValue)) {
    displayValue = displayValue.match(literalQuotesRe)[1]
  }

  return (
    <SelectedAttributeElement useOperator={!!operator} keyword={keyword}>
      <div title={title} className="property">
        <Typography variant="body2" style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>
          {property}
        </Typography>
      </div>
      {operator && (
        <div className="operator">
          <Typography variant="body2">{operator}</Typography>
        </div>
      )}
      {displayValue && (
        <div className="value">
          <Typography variant="body2">{displayValue}</Typography>
        </div>
      )}
      <IconButton aria-label="delete" size="medium" onClick={onDelete} className="delete-icon">
        <DeleteIcon />
      </IconButton>
    </SelectedAttributeElement>
  )
}

AdvancedSearchSelectedAttribute.propTypes = {
  // Name of project, collection
  property: PropTypes.string.isRequired,
  // Action on delete button press
  onDelete: PropTypes.func.isRequired,
  // Type of operator being used
  operator: PropTypes.string,
  // Value (used for keyword properties)
  value: PropTypes.string,
  /** Used for right justification */
  keyword: PropTypes.bool,
}

AdvancedSearchSelectedAttribute.defaultProps = {
  keyword: false,
  operator: '',
  value: '',
}

export default AdvancedSearchSelectedAttribute
