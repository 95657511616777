import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Accordion, AccordionDetails, AccordionSummary, Button, Tooltip} from '@mui/material'
import {Geometry, envelope, featureCollection, point} from '@turf/turf'
import {
  CloudDownload as CloudDownloadIcon,
  Pageview as PageviewIcon,
  ExpandMore as ExpandMoreIcon,
  InsertDriveFile as FileIcon,
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material'
import styled from 'styled-components'
import {uploaderContext} from '../context/uploaderContext'

import {itemContext} from '../context/itemContext'
import downloadImagineAsset from '../utilities/downloadImagineAsset'
import viewer from '../utilities/imageViewerService'

import ItemMetadataAssetTable from './ItemMetadataAssetTable'
import validBoundingBox from '../helpers/validBoundingBox'
import useToastNotification from '../hooks/useToastNotification'
import {assetTooLarge} from './ItemMetadataAssetList'
import {useDispatch} from 'react-redux'
import {
  initializeUploader,
  setUploaderWorkflowStage,
  addAvailableWorkflowStages,
} from '../actions/imageUploaderAction'
import {Asset} from '@bayer-int/imagine-sdk-browser'
import {telemetry} from '../utilities/telemetry'
import ItemVideoDialog from './ItemVideoDialog'
import {isSupportedVideoMediaType} from '../utilities/video'
import ItemAnimationDialog from './ItemAnimationDialog'
import {isSupportedAnimatedMediaType} from '../utilities/animated'

const AccordionSummaryContent = styled.div`
  display: flex;
  align-items: center;
`
const IconContainer = styled.div`
  color: var(--mdc-theme-primary);
  width: 3.5em;
  &::after {
    content: '${props => props.fileType}';
    color: white;
    font-family: Arial, Helvetica, sans-serif;

    position: relative;
    left: -26px;
    font-size: 8px;
    bottom: 7px;
    text-transform: uppercase;
  }
`

const half = {width: '100%', gridColumn: 'span 1'}

const FilenameIcon = ({fileType, mediaType}) => (
  <IconContainer title={mediaType} fileType={fileType}>
    <FileIcon fontSize="large" />
  </IconContainer>
)

function createIconName(mediaType: string) {
  if (mediaType.includes('/')) {
    const abbreviation = mediaType.split('/').pop().toLowerCase()
    if (abbreviation === 'quicktime') {
      return 'QT'
    }
    if (abbreviation === 'webm') {
      return 'WEB'
    }
    if (abbreviation === 'mpeg') {
      return 'MPG'
    }
    if (abbreviation === 'jpeg') {
      return 'JPG'
    }
    return abbreviation
  }
  return mediaType
}

/**
 * A series of metadata tables for each asset belonging to an item.
 * The tables also collapse and expand.
 */
function ItemMetadataAssetAccordion(props: {
  asset: Asset
  index: any
  openAccordionPanel: any
  setOpenAccordionPanel: any
  name: any
}) {
  const {asset, index, openAccordionPanel, setOpenAccordionPanel, name} = props
  const {errorNotification} = useToastNotification()
  const dispatch = useDispatch()
  const {setWorkflowType, setMetadata} = useContext(uploaderContext)
  const {itemMetadata, setItemAssetViewerLocation} = useContext(itemContext)
  const {
    id,
    permissions,
    videoUrl,
    animatedUrl,
    metadata: {title},
  } = itemMetadata
  const [showQuickUploadButton, setShowQuickUploadButton] = useState(false)
  const [disabledPreviewReason, setDisabledPreviewReason] = useState('')
  const [videoDialogOpen, setVideoDialogOpen] = useState(false)
  const [animatedDialogOpen, setAnimatedDialogOpen] = useState(false)

  const currentURL = new URLSearchParams(window.location.href)
  const viewParam = currentURL.get('preview')
  const validImageByHref = viewer.ImageViewer.isSupported(asset?.href)
  const validImageByAssetType = asset.mediaType && viewer.ImageViewer.isSupported(asset?.mediaType)
  const supportedFileType = validImageByHref || validImageByAssetType
  const disabledReason =
    supportedFileType === 'TMS' && !validBoundingBox(itemMetadata.bbox)
      ? 'Add a geometry to this item to enable TMS preview'
      : undefined
  const downloadDisabledReason =
    supportedFileType === 'TMS' ? 'Unable to download services' : undefined
  const isThumbnail = asset.roles?.includes('thumbnail')
  const mediaType = asset.mediaType || validImageByHref || '?'
  const fileType = createIconName(asset.mediaType || validImageByHref || '?')
    .toUpperCase()
    .slice(0, 3)
  const hideViewButton = asset.roles?.includes('thumbnail')
  const isEightBandAsset =
    itemMetadata.extensions.includes('loc360sp_ps_8bsr') && asset.id.includes('COG')
  const isDownloadBtnDisabled = isThumbnail
    ? false
    : !permissions.download || !!disabledReason || !!downloadDisabledReason
  const isViewBtnDisabled =
    !permissions.download || !!disabledReason || !!disabledPreviewReason || isEightBandAsset

  const handleAccordionPanelChange = panel => (event, isExpanded) => {
    setOpenAccordionPanel(isExpanded ? panel : false)
  }

  const onClickToSend = async () => {
    const item = await asset.item()
    let itemGeometry: Geometry = item.geometry
    if (itemGeometry.type === 'Point') return
    if (itemGeometry.type === 'MultiPolygon') {
      const turfPointArray = []
      for (const arr of itemGeometry.coordinates[0][0]) {
        turfPointArray.push(point(arr))
      }
      const enveloped = envelope(featureCollection(turfPointArray))
      itemGeometry = enveloped.geometry
    }
    dispatch(initializeUploader())
    const setUploaderStage = stage => dispatch(setUploaderWorkflowStage(stage))
    const addWorkflowStages = stages => dispatch(addAvailableWorkflowStages(stages))
    setWorkflowType(3) // sets workflow to orthomosaic
    addWorkflowStages([1]) // enables edit and validate metadata
    setUploaderStage(1) // navigates user to edit and validate metadata

    const itemProperties = item.properties
    const collectionDate = new Date(itemProperties.datetime as string)
    const s3Source = {
      s3Uri: asset.href,
      metadata: {
        collectionDate,
        extent: itemGeometry,
      },
    }
    setMetadata(s3Source)
  }

  const DisabledPreviewTooltip = ({children}) =>
    disabledReason ? <Tooltip title={disabledReason}>{children}</Tooltip> : <>{children}</>

  const handleDownload = async () => {
    try {
      await downloadImagineAsset(asset, id)
    } catch (e) {
      telemetry.error(e, 'Error downloading asset')
      errorNotification('Error downloading asset')
    }
  }

  const onClickView = () => {
    if (isSupportedVideoMediaType(asset.mediaType)) {
      setVideoDialogOpen(true)
    } else if (isSupportedAnimatedMediaType(asset.mediaType)) {
      setAnimatedDialogOpen(true)
    } else {
      setItemAssetViewerLocation(asset)
    }
  }

  useEffect(() => {
    async function checksToShowUploadButton() {
      const item = await asset.item()
      const extensions = await item.stacExtensions()
      const properties = item.properties
      const isApprovedCollection = extensions.some(ext => ext.id.includes('loc360usc')) // loc360_uav_sentera_collected

      const isGeoTiff = asset.mediaType?.includes('image/tiff')

      const assetItemGeometry = item.geometry
      const isPolygonOrMultiPolygon =
        assetItemGeometry?.type === 'Polygon' || assetItemGeometry?.type === 'MultiPolygon'

      const isWocog = asset.id === 'wocog'

      let imageS3Checks = true
      if (asset.id === 'image_s3') {
        const isApprovedUploadGroup = properties['loc360usc:UploadGroup'] === 'Market Development'
        const isFullMosaicQuality =
          (properties['loc360usc:MosaicQuality'] as string)?.toUpperCase() === 'FULL'
        const isRGBMosaic = (properties['loc360usc:MosaicType'] as string)?.toUpperCase() === 'RGB'
        const isRGBImageProduct =
          (properties['loc360_uav:ImageProduct'] as string)?.toUpperCase() === 'RGB'
        const afterUploadDeadline = properties['datetime'] > '2023-04-01T12:00:00Z'

        imageS3Checks =
          isApprovedUploadGroup &&
          isFullMosaicQuality &&
          isRGBMosaic &&
          isRGBImageProduct &&
          afterUploadDeadline
      }

      if (
        isApprovedCollection &&
        isGeoTiff &&
        isPolygonOrMultiPolygon &&
        !isWocog &&
        imageS3Checks
      ) {
        setShowQuickUploadButton(true)
      }
    }

    checksToShowUploadButton()
  }, [asset])

  useEffect(() => {
    async function updateDisableReason() {
      if (isSupportedVideoMediaType(asset.mediaType)) {
        return
      }
      if (!supportedFileType) {
        setDisabledPreviewReason('Image preview does not support the file format')
      } else if (asset.size > 30e6) {
        setDisabledPreviewReason('Image preview does not support files over 30 MB')
      } else if (
        asset.size === undefined &&
        supportedFileType !== 'TMS' &&
        (await assetTooLarge(asset, 30))
      ) {
        setDisabledPreviewReason('Image preview does not support files over 30 MB')
      }
    }

    updateDisableReason().catch(err => telemetry.error(err))
  }, [])

  useEffect(() => {
    if (!hideViewButton && !isEightBandAsset && viewParam && asset.id === viewParam) {
      setItemAssetViewerLocation(asset)
    }
  }, [])

  return (
    <Accordion
      expanded={openAccordionPanel === `panel-${index}`}
      onChange={handleAccordionPanelChange(`panel-${index}`)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionSummaryContent>
          <FilenameIcon mediaType={mediaType} fileType={fileType} />
          <>{name}</>
        </AccordionSummaryContent>
      </AccordionSummary>
      <AccordionDetails sx={{display: 'flex', flexDirection: 'column'}}>
        <>
          <ItemVideoDialog
            title={title}
            src={videoUrl}
            open={videoDialogOpen}
            onClose={() => setVideoDialogOpen(false)}
          />
          <ItemAnimationDialog
            title={title}
            src={animatedUrl}
            open={animatedDialogOpen}
            onClose={() => setAnimatedDialogOpen(false)}
          />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridColumnGap: '5px',
              marginBottom: '10px',
            }}
          >
            {!hideViewButton && (
              <DisabledPreviewTooltip>
                <div style={half}>
                  <Button
                    onClick={onClickView}
                    variant="outlined"
                    color="primary"
                    style={half}
                    startIcon={<PageviewIcon />}
                    disabled={isViewBtnDisabled}
                  >
                    View
                  </Button>
                </div>
              </DisabledPreviewTooltip>
            )}
            <Button
              onClick={handleDownload}
              variant="outlined"
              color="primary"
              sx={hideViewButton ? {width: '100%', gridColumn: 'span 2'} : half}
              startIcon={<CloudDownloadIcon />}
              disabled={isDownloadBtnDisabled}
            >
              Download
            </Button>
            {showQuickUploadButton && (
              <Button
                variant="outlined"
                color="primary"
                sx={{marginTop: hideViewButton ? '0px' : '5px'}}
                startIcon={<CloudUploadIcon />}
                onClick={onClickToSend}
              >
                Send to Analytics
              </Button>
            )}
          </div>

          <ItemMetadataAssetTable key={`metadata-asset-table-${index}`} asset={asset} />
        </>
      </AccordionDetails>
    </Accordion>
  )
}

ItemMetadataAssetAccordion.propTypes = {
  /** An instance of the JS SDK Asset Class */
  asset: PropTypes.shape({}).isRequired,
  /** Used in determining which window should be open */
  index: PropTypes.number.isRequired,
  /** Name of the asset */
  name: PropTypes.string.isRequired,
  /** Determines which accordion panel should be open */
  openAccordionPanel: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  /** Sets which accordion panel should be open */
  setOpenAccordionPanel: PropTypes.func.isRequired,
}

export default ItemMetadataAssetAccordion
