import React, {useContext, useState} from 'react'
import styled from 'styled-components'
import {useDispatch} from 'react-redux'
import {IconButton, Link as MuiLink, Tooltip} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {initializeUploader} from '../actions/imageUploaderAction'
import {imagineServiceContext} from '../context/imagineServiceContext'
import BasicSearchBar from './BasicSearchBar'
import AdvancedSearchContainer from './AdvancedSearchContainer'
import ControlBarIconShareUrl from './ControlBarIconShareUrl'
import InfoIcon from '@mui/icons-material/Info'
import Cql2Search from './cql2/Cql2Search'
import SettingsIcon from '@mui/icons-material/Settings'
import {SearchType, useSettings} from '../context/settingsContext'
import SettingsDialog from './settings/SettingsDialog'

const ControlBarSection = styled('section')`
  background: white;
  display: grid;
  grid-template-columns: 1fr 95px 45px 45px 45px 45px 45px;
  padding: 8px 10px;
  grid-column-gap: 5px;
  align-items: center;
  justify-items: center;

  .controlbar-icon {
    height: 30px;
  }
`

function ControlBar() {
  const {settings} = useSettings()
  const dispatch = useDispatch()
  const {loading, error} = useContext(imagineServiceContext)
  const urlToBeCopied = window.location.href
  const [settingsOpen, setSettingsOpen] = useState(false)

  const searchBarInput =
    settings.search.searchType === SearchType.CQL ? <Cql2Search /> : <BasicSearchBar />

  return (
    <ControlBarSection>
      {searchBarInput}
      <AdvancedSearchContainer />
      <div className="controlbar-icon">
        <Tooltip title="Settings" aria-label="settings">
          <IconButton onClick={() => setSettingsOpen(true)} size="small" color="primary">
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className="controlbar-icon">
        <Tooltip title="Imagine Documentation" aria-label="documentation">
          <MuiLink
            className="help-page-link"
            href="https://location360.assets.engineering/docs/imagery/imagine_loc360/visualization/"
            target="_blank"
          >
            <IconButton size="small" color="primary">
              <InfoIcon />
            </IconButton>
          </MuiLink>
        </Tooltip>
      </div>
      <div className="controlbar-icon">
        <Tooltip title="Upload images" aria-label="upload">
          <span>
            <IconButton
              aria-label="Upload"
              size="small"
              color="primary"
              onClick={() => dispatch(initializeUploader())}
              disabled={loading || !!error}
            >
              <CloudUploadIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
      <div className="controlbar-icon">
        <ControlBarIconShareUrl urlToBeCopied={urlToBeCopied} />
      </div>
      <SettingsDialog open={settingsOpen} onClose={() => setSettingsOpen(false)} />
    </ControlBarSection>
  )
}

export default ControlBar
