export type Bbox = [number, number, number, number]

export enum SearchPropertyComparisonOperator {
  GEQ = '>=',
  LEQ = '<=',
  GT = '>',
  LT = '<',
  EQ = '==',
  NEQ = '!=',
  NULL = '!',
}

export function isSearchPropertyComparisonOperator(
  op: unknown
): op is SearchPropertyComparisonOperator {
  if (typeof op !== 'string') {
    return false
  }
  return Object.values(SearchPropertyComparisonOperator).includes(
    op as SearchPropertyComparisonOperator
  )
}

export type SearchProperty = {
  property: string
  comparison: SearchPropertyComparisonOperator
  value: any
  description?: string
}

export enum SearchKeywordOperator {
  INCLUDES = '',
  MUST_INCLUDE = '+',
  MUST_NOT_INCLUDE = '-',
}

export type SearchKeyword = {operator: SearchKeywordOperator; value: any}

export type SearchParameters = {
  bbox?: Bbox
  collections?: string[]
  projects?: string[]
  properties?: SearchProperty[]
  keywords?: SearchKeyword[]
  tags?: string[]
  fromDate?: string
  toDate?: string
  filterText?: string
}
