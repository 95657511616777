import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'

import {CircleMarker, GeoJSON as GeoJSONLayer, Map as LeafletMap} from 'react-leaflet'
import styled from 'styled-components'
import * as turf from '@turf/turf'
import BingBasemap from './BingBasemap'

const MapContainer = styled.div`
  display: flex;
  height: 100%;

  #itemLocationMap {
    width: 100%;
    border: 1px solid #2980b9;
    margin: 20px;
  }
`

const MINIMUM_ALLOWED_AREA = 30000

function ItemLocationMap(props) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [mapProps, setMapProps] = useState({latLngBounds: null, zoom: 1, center: undefined})
  const [itemGeometry, setItemGeometry] = useState(<></>)
  const {geometry} = props
  const previewLocationMapRef = useRef()

  useEffect(() => {
    let zoom = 5
    const {type, coordinates} = geometry

    const geomTypeLower = type.toLocaleLowerCase()
    if (['multipolygon', 'polygon', 'point'].includes(geomTypeLower)) {
      if (['multipolygon', 'polygon'].includes(geomTypeLower)) {
        let poly: turf.Feature
        if (geomTypeLower === 'multipolygon') {
          poly = turf.multiPolygon(coordinates)
        } else {
          poly = turf.polygon(coordinates)
        }

        const centerPoint = turf.center(poly)
        const center = turf.getCoords(centerPoint).reverse()
        const bbox = turf.bbox(turf.transformScale(poly, 1.4))
        if (Math.abs(center[0]) > 90) zoom = 1

        if (turf.area(geometry) > MINIMUM_ALLOWED_AREA) {
          setItemGeometry(<GeoJSONLayer color="#2980b9" data={geometry} />)
          setMapProps({
            center,
            zoom,
            latLngBounds: [
              [bbox[1], bbox[0]],
              [bbox[3], bbox[2]],
            ],
          })
        } else {
          setItemGeometry(<CircleMarker color="#2980b9" radius="5" center={center} />)
          setMapProps({
            center,
            zoom,
            latLngBounds: null,
          })
        }

        setLoading(false)
      } else {
        const [long, lat] = coordinates
        const center = [lat, long]
        if (Math.abs(center[0]) > 90) zoom = 1

        setItemGeometry(<CircleMarker color="#2980b9" radius="5" center={center} />)

        setMapProps({
          center,
          zoom,
          latLngBounds: null,
        })
        setLoading(false)
      }
    } else {
      setError(true)
    }
  }, [])

  useEffect(() => {
    if (previewLocationMapRef.current) {
      const mapElement = previewLocationMapRef.current.leafletElement
      if (mapProps.latLngBounds) {
        mapElement.fitBounds(mapProps.latLngBounds)
      }
    }
  }, [previewLocationMapRef.current, mapProps])

  if (loading) return <></>
  if (error) {
    return <p>Unable to display map. Please contact Location360 Support.</p>
  }

  return (
    <MapContainer>
      <LeafletMap id="itemLocationMap" ref={previewLocationMapRef} {...mapProps} maxZoom={15}>
        <BingBasemap />
        {itemGeometry}
      </LeafletMap>
    </MapContainer>
  )
}

ItemLocationMap.propTypes = {
  /** Must be a valid geojson object */
  geometry: PropTypes.shape({}).isRequired,
}

export default ItemLocationMap
