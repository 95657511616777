import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Box, LinearProgress, Typography} from '@mui/material'
import styled from 'styled-components'
import {duration} from 'moment'
import formatBytes from 'pretty-bytes'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import useToastNotification from '../hooks/useToastNotification'
import {telemetry} from '../utilities/telemetry'
import type {IUploader} from '@bayer-int/orthomosaic-uploader'

const UploaderContainer = styled.section`
  display: grid;

  flex-grow: 1;

  grid-template-rows: 20px 50px 110px 1fr;
  grid-row-gap: 15px;

  .row-status-bar,
  .row-message-area {
    margin: 0 auto;
    min-width: 0;
    width: 90%;
  }

  .row-status-bar {
    display: grid;
    grid-template-columns: 80px 1fr 80px;
    grid-template-rows: 40px 20px 40px;

    .indicator-zero {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      justify-self: start;
      align-self: end;
      align-self: end;
      border-left: 1px solid #222;
      position: relative;
      padding: 0 0 4px 4px;
      top: 9px;

      & > .MuiTypography-caption {
        display: block;
        line-height: unset;
      }
    }

    .indicator-total {
      justify-self: end;
      align-self: end;
      grid-row: 1 / 2;
      grid-column: 3 / 4;
      border-right: 1px solid #222;
      position: relative;
      top: 9px;
      left: -8px;
      padding: 0 4px 4px 0;

      & > .MuiTypography-caption {
        display: block;
        line-height: unset;
        text-align: right;
      }
    }

    .progress-bar {
      align-self: start;
      padding-top: 7px;
      grid-row: 2 / 3;
      grid-column: 1 / 4;
      align-self: start;
      padding-top: 7px;
    }

    .progress-indicator {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      text-align: center;
    }
  }
`

const Path = styled('pre')`
  background-color: #f0f0f0;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  max-width: 100%;
  overflow-x: auto;
  padding: 0.5em;
  cursor: pointer;

  transition: background-color 0.35s ease-in-out;

  &:hover {
    background-color: #e8e8e8;
  }
`

let upload

/** State of upload are defined at
 *
 * https://github.platforms.engineering/location-360/orthomosaic-uploader/blob/main/src/Upload.ts#L475-L493
 *
 * 'complete' |
 'error' |
 'evaluating' |
 'ongoing' |
 'suspended'
 *
 */

type UploaderOrthoMosaicUploadingPaneProps = {
  uploader: IUploader
  setCriticalError: (error: string) => void
}

function UploaderOrthoMosaicUploadingPane(props: UploaderOrthoMosaicUploadingPaneProps) {
  const {infoNotification} = useToastNotification()

  const {setCriticalError, uploader} = props
  const [progress, setProgress] = useState({
    bytesDone: 0,
    bytesTotal: 0,
    rate: 0,
    status: 'evaluating',
    timeRemaining: -1,
    message: '',
  })
  const [targetPath, setTargetPath] = useState('')
  const [missionKey, setMissionKey] = useState('')

  let percentage = 0
  let progressMessage
  let bytesDoneFormatted = ''
  let totalBytesFormatted = ''

  if (progress.bytesTotal && progress.bytesDone >= 0) {
    percentage = Math.floor((progress.bytesDone / progress.bytesTotal) * 100)
    bytesDoneFormatted = formatBytes(progress.bytesDone)
    totalBytesFormatted = formatBytes(progress.bytesTotal)
    progressMessage = `${percentage}% – `

    if (progress.timeRemaining >= 0) {
      const timeFormatted = duration(Math.max(60e3, progress.timeRemaining)).humanize()
      const rateFormatted = formatBytes(progress.rate * 8, {bits: true})
      progressMessage += `about ${timeFormatted} remaining (${rateFormatted}/s)`
    } else {
      progressMessage += 'Calculating time remaining...'
    }
  } else {
    progressMessage = 'Reading file...'
  }

  function onUploadProgress(update) {
    if (update.status === 'error') {
      setCriticalError(update.message)
    } else {
      setProgress(update)
    }
  }

  useEffect(() => {
    if (!uploader) return

    const uploaderPromise = uploader.upload(onUploadProgress)
    setMissionKey(uploader.metadata?.missionKey)
    uploaderPromise
      .then(uploadResult => {
        upload = uploadResult
      })
      .catch(e => {
        const additionalErrorDetails = 'There was an error uploading to orthomosaic.'
        telemetry.error(e, additionalErrorDetails)
        setCriticalError(e.message)
      })

    return () => {
      if (upload?.status === 'ongoing') {
        upload.suspend()
      }
    }
  }, [uploader])

  useEffect(() => {
    if (upload) setTargetPath(upload?.targetPath)
  }, [upload])

  return (
    <UploaderContainer>
      <div className="spacer" />
      <Box display="flex" justifyContent="space-evenly" justifySelf="center">
        {progress.status === 'complete' ? (
          <Typography variant="h6">Upload complete for mission... {missionKey}</Typography>
        ) : (
          <Typography variant="h6">Uploading orthomosaic for mission... {missionKey}</Typography>
        )}
      </Box>

      <div className="row-status-bar">
        <div className="indicator-zero">
          <Typography variant="caption"> {bytesDoneFormatted}</Typography>
        </div>
        <div className="indicator-total">
          <Typography variant="caption">{totalBytesFormatted}</Typography>
        </div>

        <Box display="flex" alignItems="center" className="progress-bar">
          <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" value={percentage} color="primary" />
          </Box>
        </Box>
        {progress.status !== 'complete' && (
          <Typography className="progress-indicator" variant="body2">
            {progressMessage}
          </Typography>
        )}
      </div>
      <Box display="flex" flexDirection="column" alignItems="center" className="row-message-area">
        <Typography variant="body1" style={{flex: '1', textAlign: 'center'}}>
          {progress.message}
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center" maxWidth="100%">
          {targetPath && (
            <>
              <Typography variant="overline">Orthomosaic Location</Typography>
              <CopyToClipboard
                text={targetPath}
                onCopy={() => infoNotification('Upload S3 location copied to clipboard')}
              >
                <Path>{targetPath}</Path>
              </CopyToClipboard>
            </>
          )}
        </Box>
      </Box>
    </UploaderContainer>
  )
}

UploaderOrthoMosaicUploadingPane.propTypes = {
  /** Function that triggers critical error on failure of upload */
  setCriticalError: PropTypes.func.isRequired,
  /** Uploader object generated from the imagine orthomosaic uploader sdk */
  uploader: PropTypes.shape({}).isRequired,
}

export default UploaderOrthoMosaicUploadingPane
