import {parse} from 'query-string'
import validBoundingBox from '../helpers/validBoundingBox'
import {
  Bbox,
  SearchKeyword,
  SearchKeywordOperator,
  SearchParameters,
  SearchProperty,
  SearchPropertyComparisonOperator,
  isSearchPropertyComparisonOperator,
} from '../context/search/SearchParameters'

export function encodeAdvancedSearchQuery(searchParameters: SearchParameters): string {
  const {projects, collections, keywords, fromDate, toDate, bbox, tags, properties, filterText} =
    searchParameters
  const isTimeFilterSet = fromDate && toDate
  const isValidBbox = validBoundingBox(bbox)
  const params = []

  if (isValidBbox) {
    params.push(`bbox=${encodeBbox(bbox)}`)
  }

  if (isTimeFilterSet) {
    params.push(`daterange=${encodeDaterange(fromDate, toDate)}`)
  }

  if (collections?.length) {
    params.push(`collections=${encodeCollections(collections)}`)
  }

  if (projects?.length) {
    params.push(`projects=${encodeProjects(projects)}`)
  }

  if (tags?.length) {
    params.push(`tags=${encodeTags(tags)}`)
  }

  if (properties?.length) {
    params.push(`properties=${encodeProperties(properties)}`)
  }

  if (keywords?.length) {
    params.push(`keywords=${encodeKeywords(keywords)}`)
  }

  if (filterText?.length) {
    params.push(`filterText=${encodeFilterText(filterText)}`)
  }

  return params.join('&')
}

export function decodeAdvancedSearchQuery(
  useLocationSearch: string,
  basicSearchString = ''
): SearchParameters {
  const queryParams = parse(decodeURIComponent(useLocationSearch)) as ParseQueryOverride

  let {keywords, projects, collections, bbox, daterange, properties, tags, filterText} = queryParams

  const searchParameters: SearchParameters = {}

  if (bbox?.length) {
    searchParameters.bbox = decodeBbox(bbox)
  }

  if (properties?.length) {
    searchParameters.properties = decodeProperties(properties)
  }

  if (keywords?.length) {
    searchParameters.keywords = decodeKeywords(keywords, basicSearchString)
  }

  if (projects?.length) {
    searchParameters.projects = decodeProjects(projects)
  }
  if (collections?.length) {
    searchParameters.collections = decodeCollections(collections)
  }
  if (daterange?.includes(',')) {
    const {fromDate, toDate} = decodeDaterange(daterange)
    searchParameters.fromDate = fromDate
    searchParameters.toDate = toDate
  }
  if (filterText?.length) {
    const {cql, keywords} = decodeFilterText(filterText)
    if (keywords) {
      searchParameters.keywords = (searchParameters.keywords ?? []).concat(keywords)
    }
    searchParameters.filterText = cql
  }

  if (tags?.length) {
    searchParameters.tags = decodeTags(tags)
  }

  return searchParameters
}

function encodeBbox(bbox: Bbox) {
  if (bbox?.length !== 4) {
    throw new Error(`Invalid bbox: ${JSON.stringify(bbox)}`)
  }
  return `${bbox[0]},${bbox[1]},${bbox[2]},${bbox[3]}`
}

function decodeBbox(bbox: string): Bbox | undefined {
  if (!bbox?.length) {
    return undefined
  }
  const coords = bbox.split(',')
  if (!coords?.length) {
    return undefined
  }
  if (coords?.length !== 4) {
    throw new Error(`Invalid bbox encoding: ${bbox}`)
  }
  const bboxValue = coords.map(coord => {
    const res = Number.parseFloat(coord)
    return res
  })
  bboxValue.forEach(coord => {
    if (isNaN(coord)) {
      throw new Error(`Invalid bbox value: ${coord}. Not a number. Bbox encoded as: ${bbox}`)
    }
  })
  return bboxValue as Bbox
}

function encodeDaterange(fromDate: string, toDate: string): string {
  return `${fromDate},${toDate}`
}

function decodeDaterange(daterange: string): {fromDate: string; toDate: string} {
  if (!daterange?.length) {
    return undefined
  }
  const [fromDate, toDate] = daterange.split(',')
  if (!fromDate || !toDate) {
    throw new Error(`Invalid date range encoding: ${daterange}.`)
  }
  return {fromDate, toDate}
}

function encodeCollections(collections: string[]): string {
  return collections.join(',')
}

function decodeCollections(collections: string): string[] {
  return collections.split(',')
}

function encodeProjects(projects: string[]): string {
  return projects.join(',')
}

function decodeProjects(projects: string): string[] {
  return projects.split(',')
}

function encodeTags(tags: string[]): string {
  return tags.join(',')
}

function decodeTags(tags: string): string[] {
  return tags.split(',')
}

function encodeProperty(property: SearchProperty): string {
  const {property: p, comparison, value} = property
  const op = comparison == SearchPropertyComparisonOperator.NULL ? 'isNull' : comparison
  return `${p}+${op}+${value}`
}

function encodeProperties(properties: SearchProperty[]): string {
  return properties.map(encodeProperty).join(',')
}

function decodeProperty(property: string): SearchProperty {
  const [p, comparison, ...valuesElements] = property.split(' ')

  const op = comparison === 'isNull' ? SearchPropertyComparisonOperator.NULL : comparison

  if (!isSearchPropertyComparisonOperator(op)) {
    throw new Error(
      `Invalid search property comparison operator '${op}' derived from '${comparison}'`
    )
  }

  return {
    property: p,
    comparison: op,
    value: valuesElements.join(' '),
  }
}

function decodeProperties(properties: string): SearchProperty[] {
  return properties?.split(',').map(decodeProperty)
}

function encodeKeywords(keywords: SearchKeyword[]): string {
  return keywords
    .map(({operator, value}) => {
      const res = `${operator ?? ''}${value}`
      return res
    })
    .join(',')
}

export function decodeFilterText(filterText: string) {
  const decodedFilterText = decodeURIComponent(filterText)
  const {keywords, cql} = extractKeywords(decodedFilterText)

  return {keywords, cql: doubleQuoteAttributes(cql)}
}

/** Extracts and removes the 'keywords(...)' pattern from a string. */
export function extractKeywords(filterText: string): {
  cql: string
  keywords: SearchKeyword[]
} {
  const regex = /keywords\(([^)]*)\)/i
  const match = filterText.match(regex)

  if (match) {
    const extractedKeywords = match[1].trim()
    const cql = filterText
      .replace(regex, '')
      .replace(/\s{2,}/g, ' ')
      .trim()
      .replace(/(^and\b)|(\band$)/gi, '')
      .trim()

    return {
      cql,
      keywords: extractedKeywords.split(',').map((s: string) => {
        let kw = s.trim()
        kw = kw.substring(1, kw.length - 1)
        let op = SearchKeywordOperator.INCLUDES
        const firstChar = kw.substring(0, 1)
        if (firstChar === '+') {
          op = SearchKeywordOperator.MUST_INCLUDE
          kw = kw.substring(1)
        } else if (firstChar === '-') {
          op = SearchKeywordOperator.MUST_NOT_INCLUDE
          kw = kw.substring(1)
        }
        return {
          operator: op,
          value: `"${kw}"`,
        }
      }),
    }
  }
  return {
    cql: filterText,
    keywords: [],
  }
}

/** Wrap cql properties in double quotes until SFES fix allows unquoted properties with periods */
function doubleQuoteAttributes(str) {
  const regex = /(?<!")\bproperties\.[^,()\s=<>\"]+(?!")/g
  return str.replace(regex, match => `"${match}"`)
}

export function decodeKeywords(keywordStr: string, basicSearchString: string): SearchKeyword[] {
  const keywords = keywordStr.split(',').filter(i => i)
  const basicSearchTerms = basicSearchString.split(' ').filter(i => i)
  const allKeywords = [...new Set([...keywords, ...basicSearchTerms])]
  return allKeywords
    .map(term => term.replace(/^\s/, '+'))
    .map<SearchKeyword>(keyword => {
      const operator = keyword[0]
      switch (operator) {
        case SearchKeywordOperator.MUST_INCLUDE:
        case SearchKeywordOperator.MUST_NOT_INCLUDE:
          return {
            operator,
            value: keyword.substring(1),
          }
        default:
          return {operator: '' as SearchKeywordOperator.INCLUDES, value: keyword}
      }
    })
}

function encodeFilterText(filterText: string): string {
  return filterText
}

type ParseQueryOverride = {[key: string]: string | null}
