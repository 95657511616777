import React from 'react'
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from '@mui/material'
import {SearchDisplayWrap, SearchType, useSettings} from '../../context/settingsContext'

export function SearchSettingsForm() {
  const {settings, setSearchType, setSearchDisplayWrap} = useSettings()
  const {searchType, display} = settings.search
  const {wrap} = display

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <FormControl>
        <FormLabel id="search-settings-label">Search mode</FormLabel>
        <RadioGroup
          row
          aria-labelledby="search-settings-label"
          name="search-mode"
          value={searchType}
          onChange={(_, val) => setSearchType(val as SearchType)}
        >
          <FormControlLabel
            value={SearchType.KEYWORD}
            control={<Radio />}
            label={SearchType.KEYWORD}
          />
          <FormControlLabel value={SearchType.CQL} control={<Radio />} label={SearchType.CQL} />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel id="search-display-label">Display</FormLabel>
        <RadioGroup
          row
          aria-labelledby="search-display-label"
          name="search-display"
          value={wrap}
          onChange={(_, val) => setSearchDisplayWrap(val as SearchDisplayWrap)}
        >
          <FormControlLabel
            value={SearchDisplayWrap.NO_WRAP}
            control={<Radio />}
            label={SearchDisplayWrap.NO_WRAP}
          />
          <FormControlLabel
            value={SearchDisplayWrap.WRAP}
            control={<Radio />}
            label={SearchDisplayWrap.WRAP}
          />
        </RadioGroup>
      </FormControl>
    </div>
  )
}
