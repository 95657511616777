import React, {useState} from 'react'
import {Button, DialogActions, Typography} from '@mui/material'
import {useSelector, useDispatch} from 'react-redux'
import styled from 'styled-components'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import {closeUploader, setUploaderWorkflowStage} from '../actions/imageUploaderAction'
import useOrthoMosaicValidateTrigger from '../hooks/useOrthoMosaicValidateTrigger'
import {clearIndexedDbTable} from '../utilities/cache'
import useToastNotification from '../hooks/useToastNotification'
import {telemetry} from '../utilities/telemetry'
import DialogConfirm from './DialogConfirm'

const IconGroup = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90px;
  margin-right: -5px;

  span {
    font-size: 14px !important;
  }
`

function UploaderDialogFooterOrthoMosaic() {
  const {successNotification} = useToastNotification()
  const [error, setError] = useState('')
  const [cacheClearDialogOpen, setCacheClearDialogOpen] = useState(false)
  const [orthoMosaicValidating, setOrthoMosaicValidating] = useOrthoMosaicValidateTrigger()
  const workflowStage = useSelector(state => state.imageUploader.uploadWorkflowStage)

  const availableWorkflowStages = useSelector(state => state.imageUploader.availableWorkflowStages)
  const footerMessages = useSelector(state => state.imageUploader.footerMessages)

  const dispatch = useDispatch()
  const handleClose = () => dispatch(closeUploader())
  const setUploaderStage = stage => dispatch(setUploaderWorkflowStage(stage))

  const canUpload = availableWorkflowStages.indexOf(2) !== -1

  function onConfirmClearCache() {
    setError('')
    try {
      clearIndexedDbTable('orthomosaic-uploader')
      clearIndexedDbTable('s3-multipart-uploader', 'uploadParts')
      clearIndexedDbTable('s3-multipart-uploader', 'multipartUploads')
      successNotification('Successfully cleared cache')
    } catch (err) {
      telemetry.error(err)
      setError(`Failed to clear cache. ${err.message}`)
    }
  }

  function onCancelClearCache() {
    setCacheClearDialogOpen(false)
  }

  function handleClearCacheClick() {
    setCacheClearDialogOpen(true)
  }

  return (
    <>
      <div style={{paddingLeft: '24px', paddingRight: '24px', color: 'red'}}>{error}</div>
      {workflowStage === 1 &&
        footerMessages &&
        footerMessages.map(message =>
          message.level === 'error' ? (
            <div
              key={message.message}
              style={{paddingLeft: '24px', paddingRight: '24px', color: 'red'}}
            >
              {message.message}
            </div>
          ) : message.level === 'warning' ? (
            <div
              key={message.message}
              style={{paddingLeft: '24px', paddingRight: '24px', color: 'orange'}}
            >
              {message.message}
            </div>
          ) : (
            <div
              key={message.message}
              style={{paddingLeft: '24px', paddingRight: '24px', color: 'green'}}
            >
              {message.message}
            </div>
          )
        )}
      <DialogActions style={{flexDirection: 'row'}}>
        <>
          {workflowStage === 1 && (
            <>
              {orthoMosaicValidating ? (
                <Button disabled>
                  <Typography variant="button">Validating</Typography>
                </Button>
              ) : (
                <Button
                  onClick={() => setOrthoMosaicValidating(true)}
                  disabled={orthoMosaicValidating}
                >
                  <Typography color="primary" variant="button">
                    Validate
                  </Typography>
                </Button>
              )}
              <Button
                disabled={availableWorkflowStages.indexOf(2) === -1}
                onClick={() => setUploaderStage(2)}
              >
                <IconGroup>
                  <CloudUploadIcon color={canUpload ? 'primary' : 'inherit'} />
                  <Typography color={canUpload ? 'primary' : 'inherit'} variant="button">
                    Upload
                  </Typography>
                </IconGroup>
              </Button>
            </>
          )}
          <Button onClick={handleClearCacheClick}>Clear Cache</Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </>
      </DialogActions>
      {cacheClearDialogOpen && (
        <DialogConfirm
          title={'Are you sure you want to clear the upload cache?'}
          onConfirm={onConfirmClearCache}
          onClose={onCancelClearCache}
        />
      )}
    </>
  )
}

export default UploaderDialogFooterOrthoMosaic
