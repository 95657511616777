import React, {createContext, useState} from 'react'

/**
 * The following is the list of expected parameters of this state
 *
 * These values are set only once after data loading in the ItemPage page.
 */
const initialState = {
  assets: null,
  collection: null,
  dataAsset: null,
  geometry: null,
  id: null,
  itemDeleteFn: () => {},
  metadata: {},
  permissions: {},
  project: null,
  thumbnail: null,
  error: null,
  viewableAssets: null,
  videoUrl: null,
}

const itemContext = createContext(initialState)
const {Provider} = itemContext

/**
 * Item metadata store for the Item page
 * Provides a store to hone down on prop drilling
 * */
function ItemProvider({children}) {
  const [itemMetadata, setItemMetadata] = useState(initialState)

  /** When set to a valid href, opens the asset viewer contained in <ItemPageLayout /> */
  const [itemAssetViewerLocation, setItemAssetViewerLocation] = useState(null)

  return (
    <Provider
      value={{itemMetadata, setItemMetadata, itemAssetViewerLocation, setItemAssetViewerLocation}}
    >
      {children}
    </Provider>
  )
}

export {itemContext, ItemProvider}
