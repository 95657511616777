import React from 'react'
import {ListItemText} from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'

export function SettingsTab({
  tab,
  onClick,
  label,
  selected,
}: {
  tab: string
  onClick: (tab: string) => void
  label: string
  selected: boolean
}) {
  return (
    <ListItem disablePadding>
      <ListItemButton selected={selected} onClick={() => onClick(tab)}>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  )
}
