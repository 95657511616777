import React, {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import {Button, MenuItem, Select, Typography} from '@mui/material'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {ContentCopy as ContentCopyIcon} from '@mui/icons-material/'
import useAdvancedSearch from '../hooks/useAdvancedSearch'
import AdvancedSearchSelectorProjects from '../components/AdvancedSearchSelectorProjects'
import AdvancedSearchSelectorCollections from '../components/AdvancedSearchSelectorCollections'
import AdvancedSearchSelectorCollectionProperties from '../components/AdvancedSearchSelectorCollectionProperties'
import AdvancedSearchSelectorExtent from '../components/AdvancedSearchSelectorExtent'
import AdvancedSearchSelectorDateRange from '../components/AdvancedSearchSelectorDateRange'
import AdvancedSearchSelectorKeywords from '../components/AdvancedSearchSelectorKeywords'
import AdvancedSearchExecuteButton from '../components/AdvancedSearchExecuteButton'
import AdvancedSearchResultsPreview from '../components/AdvancedSearchResultsPreview'
import AdvancedSearchSelectorTags from '../components/AdvancedSearchSelectorTags'
import StyledIconButton from '../components/styled/StyledIconButton'
import {decodeAdvancedSearchQuery} from '../utilities/advancedSearchQueryString'
import AdvancedSearchCql2Text from '../components/AdvancedSearchCql2Text'

const SearchSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  max-width: 1200px;
  padding-right: 30px;

  section {
    display: grid;
    grid-template-columns: 55% 1fr;
    grid-column-gap: 15px;
    margin-bottom: 10px;
    margin-bottom: 10px;

    padding-bottom: 15px;
    padding-top: 15px;

    border-bottom: 1px solid white;
    /* box-shadow: 0; */
    transition: all 0.55s ease-in-out;

    &:active,
    &:hover {
      border-bottom: 1px solid var(--mdc-theme-primary);
      /* box-shadow: 0px 6px 12px 1px rgba(184, 184, 184, 0.72); */
    }
  }

  .query {
    padding: 10px;
  }

  .explainer {
    padding-left: 15px;
  }

  .full-row {
    grid-column: 1 / 3;
    margin-bottom: 30px;
    color: ${props => props.theme.palette.grey['700']} !important;
  }

  .parameter-info {
    margin-top: 10px;
    color: ${props => props.theme.palette.grey['500']};
  }
`

const Explainer = styled.div`
  padding-top: ${props => props.$paddingTop};
  padding-left: 15px;
`

const FooterBarSection = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 18px;
`

function mapQueryToJson(searchQueryString, options) {
  const params = decodeAdvancedSearchQuery(searchQueryString)
  return options?.pretty ? JSON.stringify(params, null, 4) : JSON.stringify(params)
}

function SearchQueryDisplay() {
  const SEARCH_DISPLAY_DEFAULT = 'url'
  const SEARCH_QUERY_DISPLAY_FORMAT = 'SEARCH_QUERY_DISPLAY_FORMAT'
  const defaultFormat =
    window.localStorage.getItem(SEARCH_QUERY_DISPLAY_FORMAT) || SEARCH_DISPLAY_DEFAULT
  const {searchQueryString} = useAdvancedSearch()
  const [format, setFormat] = useState(defaultFormat)
  const options = ['url', 'url - encoded', 'json', 'json - pretty']

  function onChange(evt) {
    const newFormat = evt?.target?.value
    window.localStorage.setItem(SEARCH_QUERY_DISPLAY_FORMAT, newFormat)
    setFormat(newFormat)
  }

  const text =
    format === 'url'
      ? searchQueryString
      : format === 'url - encoded'
      ? new URLSearchParams(searchQueryString).toString()
      : format === 'json'
      ? mapQueryToJson(searchQueryString)
      : format === 'json - pretty'
      ? mapQueryToJson(searchQueryString, {pretty: true})
      : searchQueryString

  if (!text) {
    return <></>
  }

  return (
    <div style={{display: 'flex', alignItems: 'center', width: '100%', gap: '8px'}}>
      <StyledIconButton style={{margin: '8px'}} size="large">
        <CopyToClipboard text={text}>
          <ContentCopyIcon />
        </CopyToClipboard>
      </StyledIconButton>
      <Select onChange={onChange} value={format} size="small">
        {options.map(o => (
          <MenuItem key={o} value={o}>
            {o}
          </MenuItem>
        ))}
      </Select>
      <div
        style={{
          background: '#f6f6f6',
          padding: '8px',
          flexGrow: 1,
          overflow: 'auto',
          width: '100%',
        }}
      >
        <pre style={{margin: 0}}>
          <code>{text}</code>
        </pre>
      </div>
    </div>
  )
}

function AdvancedSearchLayout() {
  const {toggleDrawer} = useAdvancedSearch()
  const {searchQueryString, advancedSearchTrigger} = useAdvancedSearch()
  const navigate = useNavigate()
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    const searchParams = new URLSearchParams(searchQueryString)

    if (advancedSearchTrigger && mounted.current) {
      window.localStorage.setItem('LAST_ADVANCED_SEARCH', searchParams.toString())
      navigate(`/advanced-search?${searchParams}`, {replace: true})
    }
  }, [searchQueryString, advancedSearchTrigger])

  return (
    <SearchSection>
      <div className="full-row">
        <Typography variant="body1">
          Uses the Imagine API to retrieve items. Each section allows you to narrow the number of
          potential results.
          <br />
          <br />
          <a
            href="https://location360.assets.engineering/docs/imagery/imagine_loc360/api_guide/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Read more about the Imagine API
          </a>
        </Typography>
      </div>
      <section id="test-e2e-projects-section">
        <div className="query">
          <AdvancedSearchSelectorProjects />
        </div>
        <Explainer $paddingTop="30px">
          <Typography variant="h5">Projects</Typography>

          <Typography variant="body1">Select one or more projects</Typography>
          <Typography variant="body2" className="parameter-info">
            Projects make the source of truth for user entitlement. They describe items belonging to
            a particular organization or service.
          </Typography>
        </Explainer>
      </section>
      <section id="test-e2e-collections-section">
        <div className="query">
          <AdvancedSearchSelectorCollections />
        </div>
        <Explainer $paddingTop="28px">
          <Typography variant="h5">Collections</Typography>

          <Typography variant="body1">Select one or more collections</Typography>
          <Typography variant="body2" className="parameter-info">
            Collections are responsible for the schema of its child items. They describe items
            belonging to a particular sensor or mission.
          </Typography>
        </Explainer>
      </section>
      <section id="test-e2e-properties-section">
        <div className="query">
          <AdvancedSearchSelectorCollectionProperties />
        </div>
        <Explainer $paddingTop="11px">
          <Typography variant="h5">Properties</Typography>

          <Typography variant="body1">
            Select one or more properties you seek to search by. Joins by a logical AND
          </Typography>
          <Typography variant="body2" className="parameter-info">
            Imagine items have properties that are defined by the item&lsquo;s collection. Requires
            that <em>only</em> one collection be selected.
          </Typography>
        </Explainer>
      </section>
      <section id="test-e2e-extent-section">
        <div className="query">
          <AdvancedSearchSelectorExtent />
        </div>
        <Explainer $paddingTop="5px">
          <Typography variant="h5">Extent</Typography>

          <Typography variant="body1">Select a geographic extent to restrict your items</Typography>
          <Typography variant="body2" className="parameter-info">
            This will search for all items that have a geography attribute, and whose geography lies
            partially or fully within a given boundary
          </Typography>
        </Explainer>
      </section>
      <section id="test-e2e-date-section">
        <div className="query">
          <AdvancedSearchSelectorDateRange />
        </div>
        <Explainer $paddingTop="9px">
          <Typography variant="h5">Date Range</Typography>

          <Typography variant="body2" className="parameter-info">
            Restrict the search to results between two selected dates and times. Uses the{' '}
            <code>datetime</code> property.
          </Typography>
        </Explainer>
      </section>
      <section id="test-e2e-keywords-section">
        <div className="query">
          <AdvancedSearchSelectorKeywords />
        </div>
        <Explainer $paddingTop="27px">
          <Typography variant="h5">Keywords</Typography>

          <Typography variant="body2" className="parameter-info">
            Returns results that contain an instance of a selected keyword. You can further filter
            by requiring that all results include a particular keyword, or require the absence of a
            keyword.
          </Typography>
        </Explainer>
      </section>
      <section id="test-e2e-tags-section">
        <div className="query">
          <AdvancedSearchSelectorTags />
        </div>
        <Explainer $paddingTop="26px">
          <Typography variant="h5">Tags</Typography>

          <Typography variant="body2" className="parameter-info">
            Returns results that contain all of the tags listed. This will only work with items
            whose collection has a &quot;tags&quot; property in its schema
          </Typography>
        </Explainer>
      </section>
      <section>
        <div className="query">
          <AdvancedSearchCql2Text />
        </div>
        <Explainer>
          <Typography variant="h5">Filter text</Typography>
          <Typography variant="body2" className="parameter-info">
            Write a cql2-text query to filter items.
          </Typography>
        </Explainer>
      </section>
      <SearchQueryDisplay />
      <FooterBarSection>
        <div className="start">
          <AdvancedSearchResultsPreview />
        </div>
        <div className="end">
          <Button onClick={toggleDrawer} color="inherit">
            cancel
          </Button>
          <AdvancedSearchExecuteButton />
        </div>
      </FooterBarSection>
    </SearchSection>
  )
}

export default AdvancedSearchLayout
