import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import {SettingsContent} from './SettingsContent'

export default function SettingsDialog({open, onClose}: {open: boolean; onClose: () => void}) {
  return (
    <Dialog open={open}>
      <DialogTitle>Settings</DialogTitle>
      <Divider />
      <DialogContent style={{width: '720px', height: '240px', paddingLeft: 0, paddingTop: 0}}>
        <SettingsContent />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  )
}
