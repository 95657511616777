import React, {useState} from 'react'

import {green, blue, orange, blueGrey, grey} from '@mui/material/colors/'
import CssBaseline from '@mui/material/CssBaseline'

import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'

import {ThemeProvider} from 'styled-components'

import DialogImagineUploader from '../components/DialogImagineUploader'
import ToastNotification from '../components/ToastNotification'
import ControlBar from '../components/ControlBar'
import LayoutBreadcrumbs from '../components/LayoutBreadcrumbs'
import {
  initialToastState,
  toastNotificationContext,
  toastNotificationStateContext,
  ToastState,
} from '../context/toastNotificationContext'
import {ErrorBoundary} from 'react-error-boundary'
import WarningIcon from '@mui/icons-material/Warning'

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: green,
    grey,
    status: {
      danger: orange,
    },
    background: {
      default: '#f0f2f5',
    },
    subduedText: blueGrey['300'],
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
  },
})

if (window && process?.env?.NODE_ENV === 'development') window.theme = theme

const {Provider: ToastStateProvider} = toastNotificationStateContext
const {Provider: ToastUpdaterProvider} = toastNotificationContext

function fallbackRender({error}) {
  console.error('this error for component')
  console.error(error)
  return (
    <div
      role="alert"
      style={{
        paddingTop: '128px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <WarningIcon fontSize="large" color="warning" />
      <p>Error loading page. Please try again later or contact Location360 support.</p>
    </div>
  )
}

/** A common layout shared by all the app's components */
const CommonLayout = ({children}) => {
  const [toastState, setToastState] = useState<ToastState>(initialToastState)

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <ToastUpdaterProvider value={{setToastState}}>
              <ControlBar />
              <LayoutBreadcrumbs />
              <ErrorBoundary fallbackRender={fallbackRender}>{children}</ErrorBoundary>
              <DialogImagineUploader />
              <ToastStateProvider value={{toastState}}>
                <ToastNotification />
              </ToastStateProvider>
            </ToastUpdaterProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
}

export default CommonLayout
