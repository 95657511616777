import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'

export default function ItemAnimationDialog({
  title,
  src,
  open,
  onClose,
}: {
  title: string
  src: string
  open: boolean
  onClose: () => void
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      style={{padding: 0, borderBottomLeftRadius: 0, MozBorderRadiusBottomright: 0}}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <Divider />
      <DialogContent style={{padding: 0, display: 'flex'}}>
        <img style={{width: '100%', height: 'auto'}} src={src} />
      </DialogContent>
    </Dialog>
  )
}
