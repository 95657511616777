import React, {useCallback} from 'react'

import useAdvancedSearch from '../hooks/useAdvancedSearch'
import Cql2Editor from './cql2/CqlEditor'

function AdvancedSearchCql2Text() {
  const {
    searchAttributes: {filterText},
    setFilterText,
  } = useAdvancedSearch()

  const onChange = useCallback((val: string) => {
    setFilterText(val)
  }, [])

  return <Cql2Editor value={filterText} onChange={onChange} />
}

export default AdvancedSearchCql2Text
