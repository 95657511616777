import React from 'react'
import CodeMirror, {EditorState, Extension} from '@uiw/react-codemirror'
import {EditorView} from 'codemirror'
import {cql} from 'codemirror-lang-cql'
import {syntaxHighlighting, bracketMatching} from '@codemirror/language'
import {highlightStyle} from './highlight'
import {
  autocompletion,
  completionKeymap,
  closeBrackets,
  closeBracketsKeymap,
  acceptCompletion,
} from '@codemirror/autocomplete'
import {ViewUpdate, keymap} from '@codemirror/view'
import {defaultKeymap, history, historyKeymap} from '@codemirror/commands'
import {SearchDisplayWrap, useSettings} from '../../context/settingsContext'

const theme = EditorView.theme({
  '&': {
    border: '1px solid darkgrey',
    'border-radius': '5px',
    'padding-top': '12px',
    'padding-bottom': '2px',
    'font-size': '14px',
    width: '100%',
  },
  '&.cm-editor.cm-focused': {
    outline: 'none',
  },
  '&.cm-editor .cm-line': {
    'padding-bottom': '10px',
  },
})

function Cql2Editor({
  value,
  onChange,
  extensions,
}: {
  value: string
  onChange: (val: string, viewUpdate: ViewUpdate) => void
  extensions?: Extension[]
}) {
  const {settings} = useSettings()
  const {
    display: {wrap},
  } = settings.search

  const tabCompletionKey = {
    key: 'Tab',
    run: (e: any) => acceptCompletion(e),
  }

  const _extensions = [
    cql(),
    syntaxHighlighting(highlightStyle),
    autocompletion({defaultKeymap: false}),
    closeBrackets(),
    bracketMatching(),
    history(),
    keymap.of([
      ...closeBracketsKeymap,
      ...completionKeymap.filter(keymap => keymap.key !== 'Enter'),
      ...defaultKeymap,
      ...historyKeymap,
      tabCompletionKey,
    ]),
    theme,
    EditorState.changeFilter.of(tr => tr.newDoc.lines === 1),
  ].concat(extensions ?? [])

  if (wrap === SearchDisplayWrap.WRAP) {
    _extensions.push(EditorView.lineWrapping)
  }

  return (
    <CodeMirror
      value={value}
      extensions={_extensions}
      basicSetup={false}
      onChange={onChange}
      indentWithTab={false}
    />
  )
}

export default Cql2Editor
