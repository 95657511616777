import type {Item} from '@bayer-int/imagine-sdk-browser'

const SUPPORTED_ANIMATED_MEDIA_TYPE = ['image/apng', 'image/avif', 'image/gif', 'image/webp']

export function isSupportedAnimatedMediaType(mediaType: string) {
  return SUPPORTED_ANIMATED_MEDIA_TYPE.some(t => mediaType.includes(t))
}

export async function findAnimatedAssetUrl(item: Item): Promise<string> {
  let animatedUrl = ''
  try {
    const assets = await item.fetchAssets()
    const animatedAssets = assets.filter(a => isSupportedAnimatedMediaType(a.mediaType))
    const animation = animatedAssets?.[0]
    animatedUrl = await animation?.fetchDownloadUrl()
  } catch (err) {}
  return animatedUrl
}
