import React, {useState} from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import {SettingsTab} from './SettingsTab'
import {SearchSettingsForm} from './SearchSettingsForm'
import {ItemDetailsSettingsForm} from './ItemDetailsSettingsForm'

export function SettingsContent() {
  const [tab, setTab] = useState('search')

  function handleTabClick(selectedTab: string) {
    setTab(selectedTab)
  }

  return (
    <Box display="flex">
      <Box>
        <nav aria-label="main mailbox folders">
          <List style={{paddingTop: 0}}>
            <SettingsTab
              selected={tab === 'search'}
              tab="search"
              onClick={handleTabClick}
              label="Search"
            />
            <SettingsTab
              selected={tab === 'item-details'}
              tab="item-details"
              onClick={handleTabClick}
              label="Item Details"
            />
          </List>
        </nav>
      </Box>
      <Box p={2}>
        {tab === 'search' ? (
          <SearchSettingsForm />
        ) : tab === 'item-details' ? (
          <ItemDetailsSettingsForm />
        ) : (
          <SearchSettingsForm />
        )}
      </Box>
    </Box>
  )
}
