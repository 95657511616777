import React from 'react'
import {FormControl, FormControlLabel, Switch, Typography} from '@mui/material'
import {useSettings} from '../../context/settingsContext'

export function ItemDetailsSettingsForm() {
  const {settings, setItemDetailsAutoplay} = useSettings()
  const {autoPlayVideo} = settings.itemDetails

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={autoPlayVideo}
              onChange={(_, checked) => setItemDetailsAutoplay(checked)}
              color="primary"
            />
          }
          label={<Typography variant="body2">Autoplay video previews</Typography>}
        />
      </FormControl>
    </div>
  )
}
