import {ViewUpdate} from '@uiw/react-codemirror'
import {
  ArrayExpression,
  BetweenExpression,
  BinaryExpression,
  ExpressionVisitor,
  FunctionExpression,
  GroupedExpression,
  LiteralExpression,
  LogicalExpression,
  PropertyNameExpression,
  Token,
  TokenType,
  UnaryExpression,
  UnaryToken,
  Loc,
  Parser,
  Scanner,
} from 'cql2-js'

export type CqlSearchContext = {
  collections?: string[]
  properties?: string[]
}

export class CqlSearchVisitor implements ExpressionVisitor<CqlSearchContext> {
  context: CqlSearchContext

  constructor() {
    this.context = {
      collections: [],
      properties: [],
    }
  }

  visitArrayExpression(expr: ArrayExpression): CqlSearchContext {
    expr.expressions.forEach(ex => ex.accept(this))
    return this.context
  }
  visitUnaryToken(expr: UnaryToken): CqlSearchContext {
    return this.context
  }
  visitBinaryExpression(expr: BinaryExpression): CqlSearchContext {
    const {operator, left, right} = expr
    if (
      operator instanceof Token &&
      operator.tokenType === TokenType.Equal &&
      left instanceof PropertyNameExpression &&
      left.name.lexeme === 'collection' &&
      right instanceof LiteralExpression
    ) {
      this.context.collections.push(right.value)
    } else if (
      operator instanceof Token &&
      operator.tokenType === TokenType.In &&
      left instanceof PropertyNameExpression &&
      left.name.lexeme === 'collections' &&
      right instanceof ArrayExpression
    ) {
      this.context.collections = this.context.collections.concat(
        right.expressions
          .filter(expr => expr instanceof LiteralExpression)
          .map((expr: LiteralExpression) => expr.value)
      )
    }
    left.accept(this)
    right.accept(this)
    return this.context
  }
  visitLogicalExpression(expr: LogicalExpression): CqlSearchContext {
    expr.left.accept(this)
    expr.right.accept(this)
    return this.context
  }
  visitLiteralExpression(expr: LiteralExpression): CqlSearchContext {
    return this.context
  }
  visitUnaryExpression(expr: UnaryExpression): CqlSearchContext {
    expr.right.accept(this)
    return this.context
  }
  visitPropertyNameExpression(expr: PropertyNameExpression): CqlSearchContext {
    this.context.properties.push(expr.name.lexeme)
    return this.context
  }
  visitFunctionExpression(expr: FunctionExpression): CqlSearchContext {
    expr.args.forEach(arg => arg.accept(this))
    return this.context
  }
  visitGroupedExpression(expr: GroupedExpression): CqlSearchContext {
    expr.expression.accept(this)
    return this.context
  }
  visitBetweenExpression(expr: BetweenExpression): CqlSearchContext {
    expr.operand.accept(this)
    expr.left.accept(this)
    expr.right.accept(this)
    return this.context
  }
}

function countQuotes(input: string) {
  let i = 0
  let cnt = 0
  while (i < input.length) {
    if (input.charAt(i) === "'" && input.charAt(i - 1) !== '\\') {
      cnt++
    }
    i++
  }
  return cnt
}

export function parseAutocomplete(val: string, viewUpdate: ViewUpdate) {
  const curIx = viewUpdate.state.selection.main.head
  const andIx = val.lastIndexOf(' and ', curIx)
  const orIx = val.lastIndexOf(' or ', curIx)
  let ix = andIx > orIx ? andIx : orIx
  if (ix === -1) {
    ix = 0
  } else {
    ix += 4
  }
  let completionStr = val.substring(ix, curIx)
  if (countQuotes(completionStr) % 2 === 1) {
    const quoteIx = val.indexOf("'", curIx)
    completionStr = quoteIx === -1 ? completionStr + "'" : val.substring(ix, quoteIx + 1)
  }
  const scanner = new Scanner(completionStr, (message: string, loc: Loc) => {
    console.error(loc.row, loc.col, message)
  })
  const tokens = scanner.scanTokens()
  const parser = new Parser(tokens, (message: Error) => {
    console.error(message)
  })
  const expressions = parser.parse()
  if (!expressions.length) {
    return
  }
  const expression = expressions[0]
  const visitor = new CqlSearchVisitor()

  return expression.accept<CqlSearchContext>(visitor)
}

export type SearchContext = {
  collections: string[]
  completeFor?: string
}

export function parseSearchContext(val: string, viewUpdate: ViewUpdate): SearchContext {
  const scanner = new Scanner(val, (message: string, loc: Loc) => {
    console.error(`[${loc.row}:${loc.col}] ${message}`)
  })
  const tokens = scanner.scanTokens()
  const parser = new Parser(tokens, (message: Error) => {
    console.error(message)
  })
  const expressions = parser.parse()
  if (!expressions.length) {
    return
  }
  const expression = expressions[0]
  const visitor = new CqlSearchVisitor()
  const context = expression.accept<CqlSearchContext>(visitor)

  const autocompleteContext = parseAutocomplete(val, viewUpdate)

  return {
    collections: context?.collections,
    completeFor: autocompleteContext?.properties?.[0],
  }
}
