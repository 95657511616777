import {
  Bbox,
  SearchKeyword,
  SearchParameters,
  SearchProperty,
} from '../context/search/SearchParameters'
import validBoundingBox from '../helpers/validBoundingBox'

export function searchParamsToCql(searchParameters: SearchParameters): string {
  const {projects, collections, keywords, fromDate, toDate, bbox, tags, properties, filterText} =
    searchParameters
  const isTimeFilterSet = fromDate && toDate
  const isValidBbox = validBoundingBox(bbox)
  const params = []

  if (isValidBbox) {
    params.push(encodeBbox(bbox))
  }

  if (isTimeFilterSet) {
    params.push(encodeDaterange(fromDate, toDate))
  }

  if (collections?.length) {
    params.push(encodeCollections(collections))
  }

  if (projects?.length) {
    params.push(encodeProjects(projects))
  }

  if (tags?.length) {
    params.push(encodeTags(tags))
  }

  if (properties?.length) {
    params.push(encodeProperties(properties))
  }

  if (keywords?.length) {
    params.push(encodeKeywords(keywords))
  }

  if (filterText?.length) {
    params.push(encodeFilterText(filterText))
  }

  const doubleAndRegex = /\band\s+and\b/gi
  return params.join(' AND ').replace(doubleAndRegex, 'and')
}

function encodeBbox(bbox: Bbox) {
  return bbox
}

function encodeDaterange(fromDate: string, toDate: string) {
  return `datetime BETWEEN '${fromDate}' AND '${toDate}'`
}

function encodeCollections(collections: string[]) {
  const arr = collections.map(singleQuote).join(',')
  return `collections IN (${arr})`
}

function encodeProjects(projects: string[]) {
  const arr = projects.map(singleQuote).join(',')
  return `properties.project:name.projects IN (${arr})`
}

function encodeTags(tags: string[]) {
  const arr = tags.map(singleQuote).join(',')
  return `"tags:tags" IN (${arr})`
}

function encodeProperties(properties: SearchProperty[]): string {
  return properties.map(encodeProperty).join(' AND ')
}

function encodeProperty(property: SearchProperty): string {
  const {property: p, comparison, value} = property
  return `"${p}" ${comparison} '${value}'`
}

function encodeKeywords(keywords: SearchKeyword[]): string {
  const kws = keywords
    .map(({operator: op, value}) => singleQuote(op + value.substring(1, value.length - 1)))
    .join(', ')
  return `keywords(${kws})`
}

function encodeFilterText(filterText: string): string {
  return filterText
}

function singleQuote(x) {
  return `'${x}'`
}
