import React from 'react'
import Cql2Editor from './CqlEditor'
import SearchIcon from '@mui/icons-material/Search'
import {IconButton} from '@mui/material'
import {searchParamsToCql} from '../../utilities/search'
import {
  decodeAdvancedSearchQuery,
  decodeFilterText,
  encodeAdvancedSearchQuery,
} from '../../utilities/advancedSearchQueryString'
import {useLocation} from 'react-router-dom'
import {isLocal} from '../../utilities/serviceBindings'
import useCql2Search from './useCql2Search'

function Cql2Search() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const searchTerms = decodeAdvancedSearchQuery(searchParams.toString())
  const cql = searchParamsToCql(searchTerms)
  const {
    cqlFunctionCompletionExtension,
    cqlIdentifierCompletionExtension,
    cqlCharacterLiteralCompletionExtension,
    onChange,
    value,
  } = useCql2Search({cql})

  function cqlToUrl(url: string) {
    const base = isLocal ? '/imagine' : ''
    return `${base}/advanced-search?${url}`
  }

  function search() {
    const {cql, keywords} = decodeFilterText(value)
    const url = encodeAdvancedSearchQuery({filterText: cql, keywords})
    window.location.href = cqlToUrl(url)
  }

  const onEnterKey = event => {
    if (event.key !== 'Enter' || !value) {
      return
    }
    search()
  }

  return (
    <div
      style={{display: 'flex', width: '100%', alignItems: 'center', overflowX: 'hidden'}}
      onKeyDown={onEnterKey}
    >
      <Cql2Editor
        value={value}
        onChange={onChange}
        extensions={[
          cqlFunctionCompletionExtension,
          cqlIdentifierCompletionExtension,
          cqlCharacterLiteralCompletionExtension,
        ]}
      />
      <IconButton style={{marginLeft: '4px'}} onClick={search}>
        <SearchIcon color="primary" />
      </IconButton>
    </div>
  )
}

export default Cql2Search
